/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Provider as StateProvider } from 'react-redux';

import { setupStore } from './src/store';
import { getStore } from './src/store';


import './src/lib/init';

setupStore();

export const wrapRootElement = ({ element }) => (
  <StateProvider store={getStore()}>{element}</StateProvider>
);
wrapRootElement.propTypes = {
  element: PropTypes.node,
};