import axios from 'axios';
import * as Activity from './Activity';
import { API_ENDPOINT, MOOSEND_API_URL, MOOSEND_API_KEY, MOOSEND_MAILING_LIST_ID } from '../../lib/config';
import * as StateHelper from '../../lib/state.helper';
/**
 * Module name
 */
export const MODULE = 'Shared';

/**
 * Initial state
 */

const defineInitialSate = () => ({
  posts: [],
  selectedPost: null,
  success: false,
});

export const $reset = StateHelper.createSimpleOperation(MODULE, 'reset', () => $reset.action());

/**
 * Fetch a promotion based on its unique ID
 */
export const $fetchPosts = StateHelper.createAsyncOperation(MODULE, 'fetchPosts', () => {
  return async (dispatch) => {
    Activity.processing(MODULE, $fetchPosts.NAME);
    dispatch($fetchPosts.request());
    try {
      const response = await axios.get(`${API_ENDPOINT}/posts`);
      return dispatch($fetchPosts.success({ data: response.data }));
    } catch (error) {
      dispatch($fetchPosts.failure(error));
    } finally {
      Activity.done(MODULE, $fetchPosts.NAME);
    }
    return null;
  };
});

/**
 * register
 */
export const $checkProduct = StateHelper.createAsyncOperation(MODULE, 'checkProduct', (id, secretKey) => {
  return async (dispatch) => {
    Activity.processing(MODULE, $checkProduct.NAME);
    dispatch($checkProduct.request());
    try {
      const encrypted_id = window.btoa(id);
      const encrypted_secret = window.btoa(secretKey);
      const response = await axios.post(`http://br.nanodots.com/nano/checkSerial/${encrypted_secret}/${encrypted_id}`);
    } catch (error) {
      dispatch($checkProduct.failure(error));
    } finally {
      Activity.done(MODULE, $checkProduct.NAME);
    }
    return null;
  };
});

/**
 * register
 */
export const $sendEmail = StateHelper.createAsyncOperation(MODULE, 'sendEmail', (email) => {
  return async (dispatch) => {
    Activity.processing(MODULE, $sendEmail.NAME);
    dispatch($sendEmail.request());
    try {
      await axios.post(
        `${API_ENDPOINT}/registered-users`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*'
          },
        },
      );
      dispatch($sendEmail.success());
    } catch (error) {
      dispatch($sendEmail.failure(error));
    } finally {
      Activity.done(MODULE, $sendEmail.NAME);
    }
    return null;
  };
});

/**
 * register
 */
export const $subscribeNewsletter = StateHelper.createAsyncOperation(MODULE, 'subscribeNewsletter', (email) => {
  return async (dispatch) => {
    Activity.processing(MODULE, $subscribeNewsletter.NAME);
    dispatch($subscribeNewsletter.request());
    try {
      await axios.post(
        `${MOOSEND_API_URL}/subscribers/${MOOSEND_MAILING_LIST_ID}/subscribe.json?apikey=${MOOSEND_API_KEY}`,
        JSON.stringify({
          Name: '',
          Email: email,
          HasExternalDoubleOptIn: false,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*'
          },
        },
      );
      dispatch($subscribeNewsletter.success());
    } catch (error) {
      dispatch($subscribeNewsletter.failure(error));
    } finally {
      Activity.done(MODULE, $subscribeNewsletter.NAME);
    }
    return null;
  };
});

/**
 * Fetch a promotion based on its unique ID
 */
export const $fetchPost = StateHelper.createAsyncOperation(MODULE, 'fetchPost', (id) => {
  return async (dispatch) => {
    Activity.processing(MODULE, $fetchPost.NAME);
    dispatch($fetchPost.request());
    try {
      const response = await axios.get(`${API_ENDPOINT}/posts/${id}`);
      return dispatch($fetchPost.success({ data: response.data }));
    } catch (error) {
      dispatch($fetchPost.failure(error));
    } finally {
      Activity.done(MODULE, $fetchPost.NAME);
    }
    return null;
  };
});

/**
 * Reducer
 */
export function reducer(state = defineInitialSate(), action) {
  switch (action.type) {
    case $fetchPosts.SUCCESS:
      return {
        ...state,
        posts: action.data,
      };
    case $fetchPost.SUCCESS:
      return {
        ...state,
        selectedPost: action.data,
      };
    case $sendEmail.SUCCESS:
      return {
        ...state,
        // selectedPost: action.data,
      };
    case $checkProduct.SUCCESS:
      return {
        ...state,
        success: action.data,
      };
    case $reset.ACTION:
      return {
        ...state,
        selectedPost: null,
      };
    default:
      return state;
  }
}

export function persister({ posts }) {
  return {
    posts,
  };
}
