/**
 * State
 *
 * This module is for state definition
 */

import * as Activity from '../components/Shared/Activity.state';
import * as Shared from '../components/Shared/state';

const $state = {
  Activity,
  Shared
};

export default $state;
