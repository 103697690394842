// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-newsletter-page-js": () => import("./../../../src/pages/join-newsletter-page.js" /* webpackChunkName: "component---src-pages-join-newsletter-page-js" */),
  "component---src-pages-post-page-js": () => import("./../../../src/pages/post-page.js" /* webpackChunkName: "component---src-pages-post-page-js" */),
  "component---src-pages-register-page-js": () => import("./../../../src/pages/register-page.js" /* webpackChunkName: "component---src-pages-register-page-js" */)
}

